import * as React from "react"
import type { HeadFC } from "gatsby"

const pageStyles = {
	color: "#232129",
	padding: 96,
	fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
}
const headingAccentStyles = {
	color: "#663399",
}
const paragraphStyles = {
	marginBottom: 48,
}
const codeStyles = {
	color: "#8A6534",
	padding: 4,
	backgroundColor: "#FFF4DB",
	fontSize: "1.25rem",
	borderRadius: 4,
}
const listStyles = {
	marginBottom: 96,
	paddingLeft: 0,
}
const doclistStyles = {
	paddingLeft: 0,
}
const listItemStyles = {
	fontWeight: 300,
	fontSize: 24,
	maxWidth: 560,
	marginBottom: 30,
}

const linkStyle = {
	color: "#8954A8",
	fontWeight: "bold",
	fontSize: 16,
	verticalAlign: "5%",
}

const headerImageStyle = {
	float: 'right',
};

const docLinkStyle = {
	...linkStyle,
	listStyleType: "none",
	display: `inline-block`,
	marginBottom: 24,
	marginRight: 12,
}

const descriptionStyle = {
	color: "#232129",
	fontSize: 16,
	marginTop: 10,
	marginBottom: 0,
	lineHeight: 1.5,
}

const dateStyle = {
	color: "grey",
	fontSize: 14,
	marginTop: 10,
	marginBottom: 0,
	lineHeight: 1.25,
};

const badgeStyle = {
	color: "#fff",
	backgroundColor: "#088413",
	border: "1px solid #088413",
	fontSize: 11,
	fontWeight: "bold",
	letterSpacing: 1,
	borderRadius: 4,
	padding: "4px 6px",
	display: "inline-block",
	position: "relative" as "relative",
	top: -2,
	marginLeft: 10,
	lineHeight: 1,
}

const links = [
	{
		text: "Staff Engineer - WordPress VIP",
		dates: 'Aug 2016 - Present · 6 yrs 3 mos',
		description: <ul>
			<li>Setting technical direction for the engineering team and products</li>
			<li>Helping design and implement a new containerized, highly-scalable, mission-critical hosting platform</li>
			<li>Designing, planning, and building REST and GraphQL APIs, robust Elasticsearch integrations, React based frontends, CLI tools, and many more initiatives</li>
			<li>Translating business and customer needs into the team’s technical direction and execution</li>
			<li>Ownership over department's security practices</li>
			<li>Triaging, evaluating, and remediating vulnerability reports</li>
			<li>Making hiring decisions</li>
			<li>Mentoring junior team members</li>
			<li>Communicating and collaborating effectively in a fully remote environment</li>
		</ul>,
		color: "#E95800",
	},
	{
		text: "Senior Software Engineer - WordPress VIP",
		dates: 'Feb 2013 - Aug 2016 · 3 yrs 7 mos',
		description: <ul>
		<li>Providing scalability, security, and architecture guidance to the largest WordPress sites in the world</li>
		<li>Reviewing customer code for performance and security</li>
		<li>Responding to incidents and quickly debugging issues on a mission-critical platform</li>
		<li>Designing and implementing new platform features</li>
		<li>Helping others level up their skills and knowledge</li>
		<li>Communicating and collaborating effectively in a fully remote environment</li>
		</ul>,
		color: "#1099A8",
	},
	{
		text: "Lead Software Engineer - VistaWorks",
		dates: 'Jun 2006 - Feb 2013 · 6 yrs 9 mos',
		description: <ul>
			<li>Meeting with clients to plan and scope complex projects</li>
			<li>Designing, architecting, and implementing complex web and mobile solutions in a variety of industries</li>
			<li>Full stack development, from SysAdmin tasks, to PHP and ASP.net, to JavaScript and CSS</li>
			<li>Android and iOS development, including server-side application and REST API design and implementation</li>
			<li>Designed, architected, and implemented AWS-based bespoke photography management and ecommerce application</li>
			<li>Seting the technical direction for the team</li>
			<li>Mentoring other engineers</li>
		</ul>,
		color: "#BC027F",
	},
	{
		text: "Web Developer - Dharma Communications",
		dates: '2007 - 2009',
		description:
			"Designing and implementing web solutions for prominent organizations and individuals in the coaching and wellness fields.",
		color: "#0D96F2",
	},
	/*{
		text: "Plugin Library",
		url: "https://www.gatsbyjs.com/plugins",
		description:
			"Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
		color: "#8EB814",
	},
	{
		text: "Build and Host",
		url: "https://www.gatsbyjs.com/cloud",
		badge: true,
		description:
			"Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
		color: "#663399",
	},*/
]

const IndexPage = () => {
	return (
		<main style={pageStyles}>
			<img style={headerImageStyle} src="https://en.gravatar.com/userimage/13003935/0bb9b24f5c45c751c9ed90a02466385d.jpg?size=256" />
			<h1 style={headingStyles}>
				Nick Daugherty
			</h1>

			<p style={paragraphStyles}>
				Staff Software Engineer
			</p>
	
			<ul style={listStyles}>
				{links.map(link => (
					<li key={link.url} style={{ ...listItemStyles, color: link.color }}>
						<span>
								{link.text}
								<p style={dateStyle}>{link.dates}</p>
							<p style={descriptionStyle}>{link.description}</p>
						</span>
					</li>
				))}
			</ul>
		</main>
	)
}

export default IndexPage

export const Head: HeadFC = () => <title>Nick Daugherty</title>
